import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React from 'react'

import { minWidth } from '@emico/styles'

import { CraftProductPageBlocksFragmentCraftFaqItemsEntry } from '../lib/craftFragments.generated'
import theme from '../theme'
import Accordion, { AccordionHeader } from './Accordion'
import CraftBlock from './CraftBlock'
import CraftLink from './CraftLink'
import HtmlContent from './HtmlContent'
import { StyledSectionTitle } from './SectionHeader'

const StyledCraftBlock = styled(CraftBlock)`
  padding-left: ${theme.containerPadding};
  padding-right: ${theme.containerPadding};
`

const Header = styled.header`
  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing.xs};
  }
`

const StyledAccordion = styled(Accordion)`
  border-bottom: ${theme.borders.default};

  ${AccordionHeader} {
    padding: ${theme.spacing.md} 0;
  }
`

const ItemLabel = styled.span`
  font-weight: ${theme.fontWeights.bold};
  padding-right: ${theme.spacing.md};
`

const ItemContent = styled.div`
  padding-bottom: ${theme.spacing.lg};
`

const StyledCraftLink = styled(CraftLink)`
  color: ${theme.colors.text};

  &:hover {
    color: ${theme.colors.text};
  }
`

interface Props {
  block: CraftProductPageBlocksFragmentCraftFaqItemsEntry
}

const CraftProductFaqItemsBlock = ({ block, ...other }: Props) => {
  if (!block.genericfaqitems || block.genericfaqitems.length === 0) {
    return null
  }

  return (
    <StyledCraftBlock {...other}>
      <Header>
        <StyledSectionTitle title={block.title} hasLargeTitle />
      </Header>

      {block.genericfaqitems.map(
        (faqItem) =>
          faqItem?.__typename === 'CraftProductPageFaqItemEntry' &&
          faqItem.answer && (
            <StyledAccordion label={<ItemLabel>{faqItem.title}</ItemLabel>}>
              <ItemContent>
                <HtmlContent html={faqItem.answer} />

                {faqItem.linkField?.url && (
                  <StyledCraftLink craftLink={faqItem.linkField}>
                    {faqItem.linkField.customText ?? (
                      <Trans>More about this</Trans>
                    )}
                  </StyledCraftLink>
                )}
              </ItemContent>
            </StyledAccordion>
          ),
      )}
    </StyledCraftBlock>
  )
}

export default CraftProductFaqItemsBlock
