import { ProductParentFragment } from '@emico-hooks/product-fragment'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'

import { Maybe, ProductStockStatus } from '@emico/graphql-schema-types'
import { CheckmarkRoundIcon } from '@emico/icons'
import { maxWidth, minWidth } from '@emico/styles'
import { Loader } from '@emico/ui'

import { CraftUspFragment } from '../lib/craftFragments.generated'
import { AlertProps } from '../lib/customTypes'
import getIsPreorder from '../lib/getIsPreorder'
import { DefaultLayoutStaticData } from '../lib/useCraftGlobalSets'
import { ProductConfigurableOptions } from '../lib/useProductConfigurables'
import { useWishlist } from '../lib/useWishlist'
import { ProductFragment } from '../packages/product-fragment'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import ColorVariants from './ColorVariants'
import ConfigurableAddToCartForm from './ConfigurableAddToCartForm'
import ProductPageHeader from './ProductPageHeader'
import RegularFinalPrice from './RegularFinalPrice'
import UspsList from './UspsList'
import { WishlistIconButton } from './WishlistIconButton'

const InfoBlock = styled.div`
  @media ${minWidth('lg')} {
    position: sticky;
    top: calc(
      ${theme.sizes.headerHeight} + ${theme.sizes.headerCollapsibleHeight}
    );
  }
`

const Scroller = styled.div`
  padding: 0 ${theme.spacing.lg};

  @media ${minWidth('lg')} {
    height: calc(
      100vh -
        (${theme.sizes.headerHeight} + ${theme.sizes.headerCollapsibleHeight})
    );
    overflow: auto;
    padding: ${theme.spacing.lg} ${theme.spacing.xl};
  }

  @media ${minWidth('xl')} {
    padding: ${theme.spacing['2xl']};
  }
`

const StyledProductPageHeaderDesktop = styled(ProductPageHeader)`
  margin-bottom: ${theme.spacing.xl};

  @media ${maxWidth('md')} {
    display: none;
  }
`

const AddToCartFormContent = styled.div`
  margin-bottom: ${theme.spacing.md};
`

const StyledRegularFinalPrice = styled(RegularFinalPrice)`
  margin-bottom: ${theme.spacing.xxs};

  &:last-child {
    margin-bottom: 0;
  }
`

const AvailabilityText = styled.span`
  display: flex;
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
`

const IconWrapper = styled.div`
  margin-right: ${theme.spacing.sm};
  font-size: ${theme.fontSizes.md};
  width: 16px;
`

const PreorderText = styled.p`
  margin: ${theme.spacing.xs} 0 0;
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
`

const Time = styled.time`
  font-weight: ${theme.fontWeights.normal};
`

const OutOfStockText = styled.p`
  margin: ${theme.spacing.xs} 0 0;
  color: ${theme.colors.error};
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
`

const StyledUspsList = styled(UspsList)`
  margin-top: ${theme.spacing.xl};
`

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.backgroundLight};
  opacity: 0.5;
  z-index: 1;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  flex-grow: 1;
`

const StyledWishlistIconButton = styled(WishlistIconButton)`
  margin-left: ${theme.spacing.sm};
  width: 49px;
  aspect-ratio: 1 / 1;
`

interface Props extends DefaultLayoutStaticData {
  product: ProductFragment
  /**
   * Product parent, in case product is SimpleProduct with a parent product
   */
  productParentData?: ProductParentFragment | null
  /**
   * Function that will be called on cart state change
   */
  handleCartStateChange: (alert: AlertProps | null) => void
  /**
   * Product page USPs
   */
  usps: Array<Maybe<CraftUspFragment>>
  /**
   * Configurable options, if product is ConfigurableProduct
   */
  configurableOptions?: ProductConfigurableOptions[]
  /**
   *
   * Function to set selected configurable values
   */
  onConfigurableValueSelect(values: string[]): void
  showReviewsModal: boolean
  setShowReviewsModal: (showReviewsModal: boolean) => void
  isLoading: boolean
  disableEcommerce: boolean
}

const ProductInfo = ({
  websiteData,
  product,
  productParentData,
  handleCartStateChange,
  usps,
  configurableOptions,
  onConfigurableValueSelect,
  showReviewsModal,
  setShowReviewsModal,
  isLoading,
  disableEcommerce,
  ...other
}: Props) => {
  const { getItemBySku, wishlist } = useWishlist()
  const isOutOfStock = product?.stockStatus === ProductStockStatus.OUT_OF_STOCK
  const preorder = product?.preorder
  const isPreorder = getIsPreorder(preorder)
  const isMainProduct =
    product?.isMainProduct || product?.__typename === 'ConfigurableProduct'

  const craftEcommerceGlobalSet = websiteData?.find(
    (globalSet) => globalSet?.__typename === 'CraftEcommerceGlobalSet',
  )

  const disabledEcommerceLink =
    craftEcommerceGlobalSet?.__typename === 'CraftEcommerceGlobalSet'
      ? craftEcommerceGlobalSet.disabledEcommerceLink
      : undefined

  return (
    <InfoBlock {...other}>
      <Scroller>
        {product?.name && (
          <StyledProductPageHeaderDesktop
            title={product.name}
            reviewRating={product.ratingSummary ?? 0}
            reviewsCount={product.reviewCount ?? 0}
            showReviewsModal={showReviewsModal}
            setShowReviewsModal={setShowReviewsModal}
          />
        )}

        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}

        {product?.colorVariants &&
          product.colorVariants.filter(
            (variant) => variant?.status === 1 && !variant.isMainProduct,
          )?.length !== 0 && (
            <ColorVariants
              product={product}
              colorVariants={product.colorVariants
                .filter(stripMaybes)
                .filter(
                  (variant) => variant.status === 1 && !variant.isMainProduct,
                )}
            />
          )}

        {disableEcommerce ? (
          <ButtonWrapper>
            {disabledEcommerceLink?.url && disabledEcommerceLink.customText ? (
              <StyledButtonPrimary href={disabledEcommerceLink.url}>
                {disabledEcommerceLink?.customText}
              </StyledButtonPrimary>
            ) : (
              <Trans>Not available online. Find a store in your area.</Trans>
            )}

            <StyledWishlistIconButton
              analyticsContext="add.to.wishlist.form"
              analyticsName="submit"
              asButton
              wishlistId={wishlist?.id ?? ''}
              wishlistItem={getItemBySku(product.sku)}
              product={product}
            />
          </ButtonWrapper>
        ) : (
          <ConfigurableAddToCartForm
            product={product}
            productParentData={productParentData}
            isOutOfStock={isOutOfStock}
            handleCartStateChange={handleCartStateChange}
            onConfigurableValueSelect={onConfigurableValueSelect}
            configurableOptions={configurableOptions}
            showButtonsOnly={disableEcommerce}
          >
            <AddToCartFormContent>
              {!isMainProduct && (
                <StyledRegularFinalPrice
                  finalPrice={product?.priceRange.minimumPrice.finalPrice}
                  regularPrice={product?.priceRange.minimumPrice.regularPrice}
                  percentOff={
                    product?.priceRange.minimumPrice.discount?.percentOff
                  }
                  isLarge
                />
              )}

              {!isMainProduct &&
                (isPreorder &&
                preorder?.atpDate &&
                preorder.atpDateFormatted ? (
                  <PreorderText>
                    <Trans>
                      Available from:{' '}
                      <Time dateTime={preorder.atpDate}>
                        {preorder?.atpDateFormatted}
                      </Time>
                    </Trans>
                  </PreorderText>
                ) : isOutOfStock ? (
                  <OutOfStockText>
                    <Trans>Sold out</Trans>
                  </OutOfStockText>
                ) : (
                  <AvailabilityText>
                    <IconWrapper>
                      <CheckmarkRoundIcon />
                    </IconWrapper>

                    <Trans>Directly available</Trans>
                  </AvailabilityText>
                ))}
            </AddToCartFormContent>
          </ConfigurableAddToCartForm>
        )}

        {!disableEcommerce && <StyledUspsList usps={usps} />}
      </Scroller>
    </InfoBlock>
  )
}

export default ProductInfo
